<template>
  <div>
    <v-row
      justify="end"
      align="center"
      no-gutters
      class="mb-2"
    >
      <v-col>
        <v-alert
          text
          prominent
          icon="mdi-information-outline"
          color="primary"
          class="mb-0"
        >
          <v-row align="center" no-gutters>
            <v-col>
              <span
                class="headline ml-5"
                v-text="$t('criticalchain.removeMultitask.message')"
              />
            </v-col>
            <v-col cols="auto">
              <v-btn
                :loading="loadingRemoveMultitask"
                :disabled="!isGanttValid || loadingRemoveMultitask"
                class="mx-1"
                color="accent"
                @click="removeMultitask"
                v-text="$t('general.next')"
              />
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <frappe-gantt
      ref="gantt"
      v-model="internalTaskList"
      show-conflict
    />
  </div>
</template>

<script>
import FrappeGantt from '../components/FrappeGantt'
import AVAILABLE_ACTIONS from '../availableActions'

export default {
  name: 'RemoveMultitaskContainer',
  components: {
    FrappeGantt,
  },
  props: {
    currentPlayer: {
      type: Object,
      required: true,
    },
  },
  inject: [
    'sendPlayerAction',
  ],
  data () {
    return {
      internalTaskList: [],
      isGanttValid: false,
      loadingRemoveMultitask: false,
    }
  },
  watch: {
    currentPlayer: {
      immediate: true,
      handler () {
        const taskList = JSON.parse(JSON.stringify(this.currentPlayer.taskList))
        const bufferLabel = this.currentPlayer.bufferSize || this.$t('criticalchain.bufferNotDefined')
        this.internalTaskList = taskList.map(item => (item.id === 'Buffer'
          ? { ...item, name: `${item.id} (${bufferLabel})` }
          : item))
      },
    },
    internalTaskList () {
      if (this.$refs?.gantt?.validate) {
        this.isGanttValid = this.$refs.gantt.validate()
      }
    },
  },
  mounted () {
    this.isGanttValid = this.$refs.gantt.validate()
  },
  methods: {
    async removeMultitask () {
      try {
        this.loadingRemoveMultitask = true
        await this.sendPlayerAction(AVAILABLE_ACTIONS.removeMultitask, {
          taskList: this.internalTaskList,
        })
      }
      finally {
        this.loadingRemoveMultitask = false
      }
    },
  },
}
</script>
